import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import "react-tabs/style/react-tabs.css"
import { Sizes } from "./sizes"
import { useSelector, useDispatch } from "react-redux"
import cart from "../assets/cart.png"
import { Submitter } from "./submitter"
import { addToCart,  startCheckout } from "../actions"
import { Loader } from "./banners"
import { getRefreshedProduct } from "../utils/utils"

export const Vignette = ({ size, p, skipOrder = false }) => {
  const vignetteSize = size || "medium"
  const [sizeSel, setSizeSel] = useState("")
  const [sizeMissing, setSizeMissing] = useState(false)
  const dispatch = useDispatch()
  const refreshed = getRefreshedProduct(useSelector(state => state), p.mongodb_id)
  const product = {...p, ...refreshed}

  const add = () => {
    if (sizeSel == "") {
      setSizeMissing(true)
      return Promise.resolve()
    } 
    return dispatch(addToCart({product, size: sizeSel}))
    .then(() => dispatch(startCheckout()))
  }

  return (
    <div style={{ display: "inline-block", width: "auto" }}>
      <div style={{}}>
        <Link to={"/" + product.fields.slug}>
          <Img fixed={product.pics[0].localImage[vignetteSize].fixed} />
        </Link>
      </div>

      <div
        style={{
          display: "inline-block",
          width: vignetteSize == "medium" ? "304px" : "468px",
        }}
      >
        <Link to="/product">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: "1em", fontWeight: "500" }}>
              {product.name}
            </div>
            <div style={{}}>{product.price} €</div>
          </div>
        </Link>

        {skipOrder || (
          <div>
            <div style={{ fontStyle: "italic" }}>
              Existe aussi en anthracite
            </div>
            <div>Coton bio</div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div>
                <Sizes
                  w={vignetteSize == "medium" ? 25 : 50}
                  stock={product.stockarray}
                  sel={sizeSel}
                  onSelected={setSizeSel}
                  sizeMissing={sizeMissing}
                  setSizeMissing={setSizeMissing}
                />
              </div>

              <Submitter action={add} loader={<Loader />} buttonType="transparent">
                <img src={cart} style={{transform: 'translateY(5px)', margin: '0 0.6em 0 0'}} />
                Achat rapide
              </Submitter>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
