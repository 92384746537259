import React, { useEffect } from "react"
import { useSelector, useDispatch, Provider } from "react-redux"
import store from "../store"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Spacer, Footer, NeedHelp, Title } from "../components/banners"
import "../utils/global.css"
import logo from "../assets/logo.png"
import CartPic from "../assets/cart.png"
import { ContextualCart } from "./cart"
import styled from "styled-components"

const Member = ({ member }) => (
  <Link to="/app/profile">Bonjour {member.firstname} !</Link>
)

const Login = () => <Link to="/login">Espace client</Link>

export const Banner = props => {
  const cart = useSelector(state => state.cart)
  const member = useSelector(state => state.member)
  return (
    <div
      style={{
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
        margin: "30px 0  100px 0",
        textTransform: "uppercase",
        fontWeight: "400",
      }}
    >
      <Link to="/">
        {/* <img style={{margin: 0}} srcset="/logo.png, /logo@2x.png 2x"></img> */}
        <img style={{ margin: 0 }} src={logo} style={{ width: "231px" }} />
      </Link>
      <div>
        <Link to="/shop">Shop</Link>
      </div>
      <div>
        <Link to="/qui-sommes-nous">About</Link>
      </div>
      <div>Où nous trouver</div>
      <div>Lookbook</div>
      <Link to="/app/cart">
        <img src={CartPic} style={{ marginBottom: 0 }}></img>{" "}
        {cart && cart.items && cart.items.length ? cart.items.length : ""}
      </Link>
      {member ? <Member member={member} /> : <Login />}
    </div>
  )
}

export default ({ children, mode }) => {
  return (
    <StoreOnly>
      <div style={{ margin: `0 auto`, maxWidth: 1200, padding: `0 0` }}>
        <Banner />

        {children}

        <Footer />
      </div>
    </StoreOnly>
  )
}

export const StoreOnly = ({ children }) => {
  useEffect(() => {
    console.log("store component mounted")
  }, [])
  return <Provider store={store}>{children}</Provider>
}

const Help = styled(NeedHelp)`
  margin-left: 60px;
  position: sticky;
  top: 40px;
`

export const ThreeCols = ({ children, shippingOption }) => {
  const cart = useSelector(state => state.cart)
  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
      <div>
        <div style={{ marginRight: "60px", position: "sticky", top: "40px" }}>
          <Title>Mon panier</Title>
          <ContextualCart cart={cart} shippingOption={shippingOption} />
        </div>
      </div>
      <div>{children}</div>
      <div>
        <Help />
      </div>
    </div>
  )
}
