import React from "react"
import Img from "gatsby-image"
import { Vignette } from "../components/vignette"
import { Spacer } from "../components/banners"

export default ({ products, skipOrder = false }) =>
  Array.isArray(products) && products.length ? (
    <div>
      <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
        Vous aimerez aussi
      </h1>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {products.map(p => (
          <div key={p.mongodb_id} style={{ margin: "0 15px" }}>
            <Vignette p={p} skipOrder={skipOrder}></Vignette>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div></div>
  )
