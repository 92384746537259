import React from "react"
import { Selector } from "./selector"
import "./sizes.css"
import styled, { keyframes } from "styled-components"
import Tada from "@bit/formidablelabs.react-animations.tada"
const TadaAnimation = keyframes`${Tada}`

export const Sizes = ({ sel, stock, onSelected, w = 50, sizeMissing, setSizeMissing }) => {
  const setSel = idx => {
    onSelected(stock[idx].size)
  }
  const width = w + "px"
  const selIndex = stock.findIndex(x => x.size == sel)

  const SizesContainer = sizeMissing
    ? styled.div`
        animation: 1s ${TadaAnimation};
      `
    : styled.div``
  return (
    <SizesContainer onAnimationEnd={() => setSizeMissing(false)}>
      <Selector sel={selIndex} onSelected={setSel}>
        {stock.map(({ size, stock }) => (
          <div
            key={size}
            style={{ width: width, height: width, lineHeight: width }}
            className={`cell ${stock ? '' : 'not-in-stock'}`}
            disabled={!stock}
          >
            {" "}
            {size}{" "}
          </div>
        ))}
      </Selector>
    </SizesContainer>
  )
}
