import React, {useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { validate } from "json-schema"

export const useForm = (validate, action, initialState = {}, after = (x)=>x) => {
    const [s, setS] = useState(initialState)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const u = name => ({
      name,
      value: s[name],
      onChange: newValue => {
        var newState = { ...s }
        newState[name] = newValue
        setS(newState)
      },
      error: errors[name],
    })
    const submit = () => {
        const _errors = {}
        validate(s, _errors)
        const hasError = Object.keys(_errors).length > 0
        if (hasError) {
            setErrors({..._errors, top: "Oops ! Quelques informations à vérifier... 😅"})
            return Promise.resolve()
        }
        setErrors({})
        return dispatch(action(s))
        .then((x) => {
            if (after) {
                setS(after)
            }
            return x
        })
        .catch(({msg}) => {
            setErrors({top: msg})
        })
    }
    return [u, submit, errors, s, setS]
}