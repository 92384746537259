import React, { useState } from "react"
import Img from "gatsby-image"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import "./detail.css"
import { Selector } from "./selector"
import { Sizes } from "./sizes"
import { useSelector, useDispatch } from "react-redux"
import { Spacer, Avantages, Button } from "./banners"
import Related from "./related"
import Modal from "react-modal"
import { idToProduct, getRefreshedProduct } from "../utils/utils"
import { addToCart } from "../actions"
import { Submitter } from "./submitter"


Modal.setAppElement("#___gatsby")

export const ProductDetail = ({ p, data }) => {
  const [sel, setSel] = useState(0)
  const [sizeSel, setSizeSel] = useState("")
  const [modalIsOpen, setIsOpen] = useState(false)
  const [sizeMissing, setSizeMissing] = useState(false)
  const dispatch = useDispatch()
  const refreshed = getRefreshedProduct(useSelector(state => state), p.mongodb_id)
  const product = {...p, ...refreshed}

  const relatedProducts =
    Array.isArray(product.related) &&
    product.related.map(id => idToProduct(data, id)).filter(p => p)

  const add = () => {
    if (sizeSel == "") {
      setSizeMissing(true)
      return  Promise.resolve()
    } 
    return dispatch(addToCart({product, size: sizeSel}))
    .then(() => setIsOpen(true))
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* <button onClick={() => dispatch({type: "bla"})}>click</button> */}
        <div style={{ flexBasis: "468px", flexGrow: "0", flexShrink: "0" }}>
          <Img fixed={product.pics[sel].localImage.large.fixed} />
        </div>
        <div
          style={{
            flexBasis: "180px",
            flexGrow: "0",
            flexShrink: "0",
            padding: "0 30px",
          }}
        >
          <Selector sel={sel} onSelected={setSel}>
            {product.pics.slice(0, 4).map(pic => (
              <div key={pic.src} style={{ marginBottom: "30px" }}>
                <Img
                  fixed={pic.localImage.small.fixed}
                  style={{ display: "block" }}
                />
              </div>
            ))}
          </Selector>
        </div>
        <div
          style={{
            flexBasis: 1200 - 468 - 180 + "px",
            flexGrow: "0",
            flexShrink: "0",
            padding: "10px",
            textAlign: "center",
          }}
        >
          <h1 style={{ textAlign: "center", marginBottom: "10px" }}>
            {product.name}
          </h1>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "10px",
              fontStyle: "italic",
            }}
          >
            {product.price} €
          </h1>
          <div
            style={{
              textAlign: "center",
              marginBottom: "10px",
              fontStyle: "italic",
              fontSize: "1.15rem",
            }}
          >
            Un super produit
          </div>
          <Tabs>
            <TabList>
              <Tab>Description</Tab>
              <Tab>Composition</Tab>
              <Tab>Guide des tailles</Tab>
            </TabList>

            <TabPanel>
              {product.desc.split("\n").map((item, i) => (
                <React.Fragment key={i}>
                  {item}&nbsp;
                  <br />
                </React.Fragment>
              ))}
            </TabPanel>
            <TabPanel>
              <h2>Any content 2</h2>
            </TabPanel>
            <TabPanel>
              <h2>Any content 3</h2>
            </TabPanel>
          </Tabs>

          <Spacer v="3rem"></Spacer>

          <Sizes
          stock={product.stockarray}
          sel={sizeSel}
          onSelected={setSizeSel}
          sizeMissing={sizeMissing}
          setSizeMissing={setSizeMissing}
          />

          <Spacer v="2rem"></Spacer>

          <Submitter action={add} style={{width: '100%'}}>
            Ajouter au panier
          </Submitter>

          <Spacer v="2rem"></Spacer>

          <Avantages direction="column"></Avantages>
        </div>
      </div>
      <Spacer v="6rem" />
      <Related products={[product, product, product]} />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        contentLabel="Added to cart"
        className="Modal"
        overlayClassName="Overlay"
      >
        <AddedToBasket product={product} />
      </Modal>
    </div>
  )
}

const AddedToBasket = ({ product }) => {
  return (
    <div>
      <h2
        style={{
          textAlign: "center",
          letterSpacing: "0.1em",
          fontStyle: "italic",
        }}
      >
        Votre article a bien été ajouté au panier
      </h2>
      <Related products={[product, product, product]} skipOrder={true} />
      <div style={{ textAlign: "center", margin: '1.5em 0 1.5em 0' }}>
        <Button to="/shop" type='secondary' style={{marginRight: "2rem"}}>Poursuivre mes achats</Button>
        <Button to="/cart">Accéder à mon panier</Button>
      </div>
    </div>
  )
}
