import React from "react"

export const Selector = ({ children, sel = 0, onSelected = true }) => (
  <React.Fragment>
    {children.map((child, idx) => {
      var r = React.cloneElement(child, {
        className:
          (child.props.className || ``) + (idx == sel ? ` selected` : ``),
        onClick: () => !(child.props.disabled) && onSelected(idx),
        style: { ...child.props.style },
      })
      return r
    })}
  </React.Fragment>
)
