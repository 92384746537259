import React, {useState, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
import Img from "gatsby-image"
import {
  Loader,
  NeedHelp,
  Avantages,
  Spacer,
  Title,
  Input,
} from "../components/banners"
import { submitPromoCode, removePromoCode, startCheckout, removeFromCart } from "../actions"
import { Submitter } from "../components/submitter"
import { validString } from "../utils/utils"
import { useForm } from "../hooks"

const moneyStyle = {
  fontSize: "1.25em",
  textAlign: "right",
  paddingRight: "59px",
  fontWeight: "400",
}

const CartItem = ({ item }) => {
  const dispatch = useDispatch()
  const remove = () => dispatch(removeFromCart({ lineId: item.id }))
  return (
    <React.Fragment>
      <div>
        <Img fixed={item.thumb} />
      </div>
      <Submitter action={remove} loader={<Loader />} buttonType='transparent'>
        🗑
      </Submitter>
      <div style={{ fontSize: "1.25em", fontWeight: "500" }}>
        {item.name}
        {validString(item.size) && (
          <div style={{ fontWeight: "400" }}>Taille {item.size}</div>
        )}
      </div>
      <div style={{ ...moneyStyle }}>{item.price} €</div>
      <div style={{ fontSize: "1.25em" }}>1</div>
      <div style={{ ...moneyStyle }}>{item.price} €</div>
    </React.Fragment>
  )
}

const CodeItem = ({ codeObj }) => {
  const {code, discount} = codeObj
  const dispatch = useDispatch()
  const remove = () => dispatch(removePromoCode({ code: code }))
  return (
    <React.Fragment>
      <div style={{gridColumn: '5'}}>
        {code}
      <Submitter action={remove} loader={<Loader />} buttonType='transparent'>
        🗑
      </Submitter>
      </div>

      <div style={{gridColumn: '6', ...moneyStyle }}>- {discount} €</div>
    </React.Fragment>
  )
}

const AddCode = () => {
  const validate = (s, _errors) => {
    if (!validString(s.code)) _errors.code = "Veuillez saisir un code"
  }
  const [u, submit, errors] = useForm(validate, submitPromoCode, {}, {code: ""})
  const error = errors.code || errors.top
  return (
    <div><form onSubmit={(e) => {e.preventDefault(); return false}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <Input placeholder="Code promo" {...u("code")} error={null} style={{width: '10em'}}
    transform={(s) => s.toUpperCase()} />
    <Submitter error={error && true} action={submit} buttonType='transparent'>
    <span style={{fontWeight: '500'}}>OK</span></Submitter>
    
    </form>
    {error && (
    <div style={{color: 'red', fontSize: '0.9rem', marginTop: '-15px'}}>{error}</div>
    )}</div>
  )
}

const CartContainer = () => {
  const c = useSelector(state  => state.isClient)
  return (
    <Cart isClient={c} />
  )
} 

const Cart = () => {
  const {
    items = [],
    totalBeforePromo = 0,
    totalBeforeShipping = 0,
    shippingFees = {},
    codes = [],
  } = useSelector(state => state.cart)
  const dispatch = useDispatch()
  
  if (items.length == 0) {
    return (<span style={{display: 'block', textAlign: 'center'}}>Votre panier est encore vide ! </span>)
  }
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 0.2fr 2fr 1fr 1fr 1fr",
        //gridAutoRows: '200px',
        gridRowGap: '2rem',
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div></div>
      <div></div>
      <div></div>
      <div>Prix unitaire</div>
      <div>Quantité</div>
      <div>Prix total</div>
      {items.map(item => (
        <CartItem key={item.id} item={item}></CartItem>
      ))}
      <div style={{ gridColumn: "5" }}>Sous-total</div>
      <div style={{ gridColumn: "6", ...moneyStyle }}>
        {totalBeforePromo} €
      </div>

      <div style={{ gridColumn: "3",  }}>
        <AddCode />
      </div>
      <div style={{ gridColumn: "5",  }}>
        Livraison
        <br />
        <span style={{ fontStyle: "italic" }}>Offerte à partir de 200 €</span>
      </div>
      <div style={{ gridColumn: "6", ...moneyStyle }}>
        {shippingFees["normal"]} €
      </div>

      {codes.map((c) => (
        <CodeItem codeObj={c} key={c.code} />
      ))}

      <div style={{ gridColumn: "5",  }}>Total</div>
      <div style={{ gridColumn: "6", ...moneyStyle }}>
        {totalBeforeShipping + shippingFees["normal"]} €
      </div>

      <div style={{ gridColumn: "3 / 5", paddingLeft: "300px" }}>
        <NeedHelp></NeedHelp>
      </div>
      <div
        style={{
          gridColumn: "5 / 7",
          textAlign: "right",
          paddingRight: "59px",
        }}
      >
        <Submitter action={() => dispatch(startCheckout())} style={{width: '100%'}}>
          Commander
        </Submitter>
      </div>
    </div>
  )
}

const ContextualMoney = ({children}) => (
  <div style={{textAlign: 'right', fontWeight: '500'}}>{children}</div>
)

// Used in ThreeCols layout (login, signup, shipping) + orders
export const ContextualCart = ({cart, className, shippingOption = "normal", style}) => {
  const {
    items,
    totalBeforeShipping,
    shippingFees,
    codes,
    totalBeforePromo,
  } = cart
  if (items.length == 0) {
    return <span> </span>
  }
  return (
    <div
      className={className}
      style={{
        textAlign: "center",
        ...style,
      }}
    >
      {items.map(({ name, price, size, id, thumb }) => (
        <div key={id} style={{marginBottom: "3rem"}}>
          <div>
            <Img fixed={thumb} />
          </div>
          <div style={{ fontSize: "1.1em", fontWeight: "500" }}>
            {name}
            {validString(size) && (
              <div style={{ fontWeight: "400" }}>Taille {size}</div>
            )}
          </div>
        </div>
      ))}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: 'auto auto',
        }}
      >
        <div style={{ textAlign: 'left' }}>Prix</div>
        <ContextualMoney>
          {totalBeforePromo} €
          </ContextualMoney>
        <div style={{ textAlign: 'left' }}>
          Livraison
        </div>
        <ContextualMoney>
          {shippingFees[shippingOption]} €
          </ContextualMoney>

          {codes.map((c) => (
            <React.Fragment key={c.code}>
            <div style={{textAlign: 'left'}}>
              {c.code}
            </div>
            <ContextualMoney>
              - {c.discount} €
          </ContextualMoney>
          </React.Fragment>
          ))}

        <div style={{ textAlign: 'left' }}>Total</div>
        <ContextualMoney>
          {totalBeforeShipping + shippingFees[shippingOption]} €
          </ContextualMoney>
      </div>

      
    </div>
  )
}

export default () => (
  <div>
    <Cart />
    <Spacer v= "2em" />
    <Avantages />
  </div>
)