import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { ProductDetail } from "../components/detail"
import { Avantages, Spacer, Newsletter } from "../components/banners"
import { graphql } from "gatsby"
import { idToProduct } from "../utils/utils"



export default ({data, pageContext}) => (
  <Layout>
    <ProductDetail data={data} p={idToProduct(data, pageContext.productId)} />

    <Spacer v="6rem"></Spacer>

    <Avantages />

    <Spacer v="6rem"></Spacer>

    <Newsletter />
  </Layout>
)

export const query = graphql`
  query {
    allMongodbHerokuD589Dff8Mbmjproducts {
      edges {
        node {
          mongodb_id
          fields {
            slug
          }
          name
          price
          desc
          #baseline
          #compo
          #sizeguide
          stockarray {
            size
            stock
          }
          related
          pics {
            src
            localImage {
              small: childImageSharp {
                fixed(width: 120, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
              medium: childImageSharp {
                fixed(width: 304, height: 380) {
                  ...GatsbyImageSharpFixed
                }
              }
              large: childImageSharp {
                fixed(width: 468, height: 585) {
                  ...GatsbyImageSharpFixed
                }
              }
              huge: childImageSharp {
                fixed(width: 640, height: 800) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
